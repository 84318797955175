<template>
  <div class="application-add-page">
    <div class="product-list">
      <div v-for="(item, index) in list" :key="index" class="product-item">
        <div class="product-info">
          <div class="summary">
            <div class="name">
              <div>{{ item.name }}</div>
            </div>
            <div class="app-key">
              介绍：{{ item.introduce }}
            </div>
            <div class="reset-btn">
              <el-link type="primary" :underline="false" @click.stop="installApp(item)">
                安装
              </el-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMetadataList, installApp } from '@/api/application';

export default {
  data () {
    return {
      list: []
    };
  },
  watch: {
  },
  mounted () {
    this.fetch();
  },
  methods: {
    fetch () {
      getMetadataList().then(res => {
        this.list = res;
      });
    },
    installApp (item) {
      const id = item.id;
      const params = {
        metaAppId: id
      };
      installApp(params).then(res => {
        this.$message.success(res);
      });
    },
  }
};
</script>

<style lang='scss' scoped>
.application-add-page {
  .product-list {
    margin-top: 25px;
    margin-right: -25px;
    .product-item {
      // width: 25%;
      display: inline-block;
      vertical-align: top;
      border: 1px solid #e9e9e9;
      border-radius: 2px;
      margin: 0 25px 25px 0;
      background: #fff;
      width: 31.14%;
      min-width: 350px;
      .product-info {
        position: relative;
      }
      .pro-img {
        width: 100%;
      }
      .summary {
        padding: 20px 24px 16px;
        .name {
          font-size: 17px;
          color: #222;
          font-weight: 500;
          cursor: pointer;
          .subscribe {
            color: #f8841f;
            font-size: 12px;
            font-weight: 500;
            margin-left: 8px;
            cursor: default;
          }
          .no-subscribe {
            font-size: 12px;
            display: inline-block;
            color: #999;
            margin-left: 8px;
            .buy {
              cursor: pointer;
            }
          }
        }
        .app-key {
          color: #888;
          margin-top: 4px;
          word-break: keep-all;
          cursor: pointer;
        }
      }
      .reset-btn {
        margin-top: 15px;
        text-align: right;
      }
    }
  }
}
</style>
